<template>
  <div>
    <!-- pic59CDC7A6-D166-4FCB-B437-E898E3E1956F.jpg -->
    <img style="width: 220px; height: 150px" class="leftimage rounded" src="@/assets/news-network/lu-the-new-logo-1.jpg">

    <p>
      LEGO Universe will put the MASSIVE in massively multiplayer online gaming by introducing a million ways to have fun in a virtual cosmos!
    </p>
    <p>
      The shear size of LEGO Universe was a challenge to the team designing the game’s official logo: How could they symbolize an online world so huge with one little emblem?
    </p>
    <p>
      What’s more, LEGO was directing the design team to create a mark that reflects the company’s stunningly high quality standards. LEGO values will, after all, be built into every piece of its first MMOG.
    </p>
    <p>
      Read on to see how LEGO Universe’s official logo designers overcame these giant challenges to create the icon for a revolutionary online game!
    </p>
    <p>
      Before the unveiling of LEGO Universe’s new logo, three forces played into its creation.
    </p>
    <p>
      First, the design briefing from LEGO said the logo had to reflect the “excellence” of a LEGO product. Next, the game’s fans were uploading oodles of inspiring logo ideas to LEGOUniverse.com’s image gallery. Finally, the visionary LEGO Universe design team was utilizing its full arsenal of graphic expertise to make a mark for the massive new virtual world!
    </p>
    <p>
      LEGO’s design instructions said that the LEGO Universe logo should first reflect the “creativity, fun…and quality” that the company builds into all of its offerings.
    </p>
    <p>
      Since the continuous creation of LEGO Universe is in players’ hands, the logo design team also wanted input from the game’s fans. When the designers asked for logo submissions on the LEGO Universe message boards, thrilled artists uploaded a mountain of inspiring ideas!
    </p>

    <!-- picDEF2F3F3-E9D0-483E-9ECA-A4CBB12891DC.jpg -->
    <img style="width: 440px; height: 319px;" class="rounded" src="@/assets/news-network/lu-the-new-logo-2.jpg">
    <p><i>Fan logo submissions</i></p>
    <p>
      With a tough brief but lots of inspiration, the team then chose three promising directions for symbolizing the game. Here are some examples of LEGO Universe fan submissions for the game’s logo design that also captured three qualities the LEGO Universe design team also believed were important.
    </p>
    <p><b>Showing the playfulness of all LEGO products:</b></p>
    <div style="clear:both;"></div>

    <!-- pic5A2BCB45-F57E-4F42-9815-5EFC31441D5F.jpg -->
    <img src="@/assets/news-network/lu-the-new-logo-3.jpg" style="width: 180px; height: 180px;" class="rounded">
    <p><i>Submitted by TomB34</i></p>
    <p><b>Creating a bold new graphic mark:</b></p>
    <div style="clear:both;"></div>

    <!-- picD65A16DE-E5C7-413C-B0C6-286EC83C2492.jpg -->
    <img src="@/assets/news-network/lu-the-new-logo-4.jpg" style="width: 180px; height: 180px;" class="rounded">
    <p><i>Submitted by Anonymous LEGO Fan</i></p>
    <p><b>Displaying the expanse of LEGO Universe’s fun activities:</b></p>
    <div style="clear:both;"></div>

    <!-- pic43E56F09-CEF0-46D7-BA12-20A2A5F9A67D.jpg -->
    <img src="@/assets/news-network/lu-the-new-logo-5.jpg" style="width: 180px; height: 180px;" class="rounded">
    <p><i>Submitted by Anonymous LEGO Fan</i></p>
    <p>
      The LEGO Universe logo design team then tested the directions with kids, and they were surprised by the results. They liked all three logo design directions equally! How was the game’s logo design team to proceed?
    </p>
    <p>
      LEGO Universe’s official emblem makers decided to do more exploring before settling on a single idea. As they worked through their visions, they created a symbol that met all three requirements. <br>First, the spiffy new LEGO Universe symbol includes LEGO’s longstanding logo to reflect the product’s high standards of quality and fun.
    </p>
    <p>
      Next, the new logo for LEGO Universe sports a sturdy mark in the hand of a LEGO minifigure. Minifigs are the game’s central characters, and the yellow curve of their hands makes a stunning “U” for the word Universe!
    </p>
    <p>
      Finally, LEGO Universe’s official logo designers built the symbol in three dimensions. That’s because they wanted it to boast maximum potential for cinematic storytelling! What better way to show all the fun that’ll be built into LEGO Universe than to bring its logo to life?
    </p>
    <div style="clear:both;"></div>

    <!-- pic847A11B3-2C7B-49DF-8808-274D834EAE33.jpg -->
    <img style="width: 440px; height: 375px;" class="rounded" src="@/assets/news-network/lego-universe-old.jpg">
    <p><i>The official LEGO Universe logo</i></p>
    <p>
      Soon LEGO Universe animators will be taking the logo one step further by <i>really</i> bringing it to life with animation!  For now we have to patiently await the grand reveal of these cool logo animations, but in the coming weeks we will share the storyboards for them with you, so be sure to keep checking back!
    </p>
    <div style="clear:both;"></div>
  </div>
</template>
